import { FeaturePage } from "../components/FeaturePage";
import React from 'react'

export default () => (
  <FeaturePage
    heading1="Collect and store medical intake forms"
    heading2="Personalise your digital intake forms to ensure all information is captured"
    heading3="Collect and store digital intake forms"
    headerBlocks={[
      "Personalise your digital intake forms to ensure all information is captured",
      "Securely store all records of your digital intake forms",
      "Collect intake forms from every guest prior to their arrival",
      "Map your intake forms to medical contraindications and customer profiles",
    ]}
    featurePageBlocks={[
      {
        chip: 'Form creation',
        heading: 'Create your digital intake form format and content as required',
        body: [
          "Easily create and control the content for your medical intake forms within Trybe.",
          "You’re also able to create the format in which your medical intake forms are viewed and completed.",
        ]
      },
      {
        chip: 'Form completion',
        heading: 'Allow for guests to easily complete their intake forms online prior to arrival',
        body: [
          "Lead bookers will receive your medical intake form straight after completing their booking.",
          "From there, they are able to distribute your medical intake form to each individual within the booking.",
          "From a few simple clicks, each guest will be able to complete their intake form prior to arrival, speeding up the process when they arrive on the day of the booking.",
        ],
      },
      {
        chip: 'Security',
        heading: 'Securely store all medical data within Trybe',
        body: [
            "Once each guest has completed their medical intake form, each form is collated and digitally stored within Trybe.",
            "We take security and data protection very seriously and adhere to data cleansing and user-permitted restrictions so that you are able to control which members of the team can see your completed medical intake forms.",
        ]
      },
      {
        chip: 'Medical contraindications',
        heading: 'Link customer intake forms to their medical contraindication profiles',
        body: [
            "Once a guest has completed their medical intake form, you will have the ability to link their medical contraindications to their guest profile in Trybe.",
            "When the customer makes a booking in the future, this will flag any non-applicable treatments.",
        ]
      }
    ]}
  />
)
