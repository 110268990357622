import { CallToAction } from "./CallToAction";
import React from 'react'

export const Prefooter = () => (
  <div className="text-center font-medium py-12">
    <div className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
      The ultimate bookings solution
    </div>
    <div className="mt-3 max-w-md mx-auto text-base text-white opacity-50 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
      Spa treatments, spa packages, fitness classes, access to the wet facilities, sourdough making classes, membership-specific booking types, you name it, within <strong className="text-white">Trybe</strong> you've got the ability to book it.
    </div>
    <div className="mt-10">
      <CallToAction/>
    </div>
  </div>
)
