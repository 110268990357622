import React from 'react'
import { IconBackground } from './IconBackground';
import Layout from "./mdxLayout";
import { Prefooter } from './Prefooter';

interface FeaturePageBlockProps {
  chip: string,
  heading: string,
  body: string[]
}

interface FeaturePageProps {
  heading1: string,
  heading2: string,
  heading3: string,
  headerBlocks: string[],
  featurePageBlocks: FeaturePageBlockProps[]
}

export const FeaturePage: React.FC<FeaturePageProps> = ({
  heading1,
  heading2,
  heading3,
  headerBlocks = [],
  featurePageBlocks = []
}) => (
  <Layout>
    <>
      <div className="bg-grape">
        <div className="relative overflow-hidden">
          <div className="text-white opacity-10">
            <IconBackground/>
          </div>

          <div className="relative pb-16 sm:pb-18">
              <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
                <div className="text-center md:text-left lg:w-1/2">
                  <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-4xl">
                    <span data-sal="fade" data-sal-delay="" data-sal-duration="1000" data-sal-easing="ease" className="block">
                      {heading1}
                    </span>
                  </h1>
                  <p className="text-white text-lg font-medium opacity-50 mt-3">
                    {heading2}
                  </p>
                  <h2 className="text-2xl my-8 tracking-tight text-violet">
                    {heading3}
                  </h2>

                  <div className="grid grid-cols-2 text-white opacity-50 gap-10">
                    {headerBlocks.map(headerBlock => (
                      <p>{headerBlock}</p> 
                    ))}
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div className="bg-violet-light relative">
        <div className="text-violet text-opacity-25 overflow-hidden">
          <IconBackground/>
        </div>
        <div className="max-w-7xl p-8 sm:p-6 lg:p-8 lg:py-16 mx-auto space-y-32 z-10 relative">
          <div className="grid md:grid-cols-2 gap-16 text-grape">
            {featurePageBlocks.map(block => (
              <div>
                <div className="text-xs font-medium uppercase">{block.chip}</div>
                <div className="text-2xl tracking-tight font-medium my-4">
                  {block.heading}
                </div>
                <div className="space-y-4 text-grape text-opacity-50">
                  {block.body.map(bodyLine => (
                    <p>{bodyLine}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-grape relative overflow-hidden">
          <div className="text-white text-opacity-10">
            <IconBackground/>
          </div>
          <div className="max-w-6xl p-4 sm:p-6 lg:p-8 mx-auto space-y-32 z-10 relative">
            <Prefooter/>
          </div>
        </div>
      </div>
    </>
  </Layout>
)
